import { Form, Field, CellGroup, RadioGroup, Radio } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        [Form.name]: Form,
        [Field.name]: Field,
        [CellGroup.name]: CellGroup,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio
    },
    data () {
        return {
            operatorCode: '',
            sendBtnTime: 0,
            loadingShow: false,
            serialCode: '1',
            validateState: false,
            upper: '1',
            user: '',
            form: {
                name: '',
                phone: '',
                identity: '',
                vcode: ''
            }
        }
    },
    mounted () {
        this.retrieveUser()
    },
    methods: {
        async retrieveUser () {
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveLoginUser')
            if (res.status === '200') {
                this.form.name = res.data.name
                this.form.identity = res.data.identity
                this.user = res.data
            }
        },
        async submit () {
            if (this.validateState) {
                this.loadingShow = true
                var pd = { name: this.form.name, identity: this.form.identity, validate: this.form.vcode }
                const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/certifyUser', this.$qs.stringify(pd))
                this.loadingShow = false
                if (res.status === '200') {
                    this.$dialog.alert({
                        title: '提示信息',
                        message: '用户认证成功'
                    }).then(() => {
                        this.$router.go(-1)
                    })
                } else {
                    this.$dialog.alert({
                        title: '提示信息',
                        message: res.msg
                    }).then(() => {
                        // on close
                    })
                }
            } else {
                this.$dialog.alert({
                    title: '验证码提示',
                    message: '请点击发送手机验证码'
                }).then(() => {
                    // on close
                })
            }
        },
        async sendValidate () {
            var pd = { phone: this.user.phone }
            var that = this
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/sendValidate', this.$qs.stringify(pd))
            if (res.status === '200') {
                that.validateState = true
                that.sendBtnTime = 60
                that.countdown()
            }
        },
        countdown () {
            var that = this
            setTimeout(function () {
                that.sendBtnTime--
                if (that.sendBtnTime > 0) {
                    that.countdown()
                }
            }, 1000)
        }
    }
}
