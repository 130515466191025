<template>
  <div class="page">
    <Navbar title="用户绑定" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-row class="title">
        <van-col span="24">个人认证</van-col>
      </van-row>
      <van-cell-group>
        <van-field
          v-model="form.name"
          label="姓名"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        >
        </van-field>
        <van-field
          v-model="form.identity"
          label="身份证号"
          placeholder="请输入身份证号"
          :rules="[{ required: true, message: '请填写身份证号' }]"
        >
        </van-field>
        <van-field
          v-model="form.vcode"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <van-button
              size="mini"
              v-if="sendBtnTime > 0"
              :disabled="true"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码({{ sendBtnTime }})</van-button
            >
            <van-button
              size="mini"
              v-if="sendBtnTime <= 0"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码</van-button
            >
          </template>
        </van-field>
        <van-row>
          <van-col offset="8" span="8" class="subbtn">
            <van-button
              block
              type="primary"
              :color="COLOR"
              size="small"
              native-type="submit"
            >
              保存认证
            </van-button></van-col
          >
        </van-row>
      </van-cell-group>
    </van-form>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
    <Share ref="share" />
  </div>
</template>
<script>
import User from './User.js'
export default {
  ...User
}
</script>
<style lang="less" scoped>
@import './User.less';
</style>
